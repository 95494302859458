<template>
  <v-card>
    <v-card-text>
      <v-progress-linear
        v-if="isReportByLocationLoading"
        indeterminate
      />
      <v-row v-if="show">
        <v-col>
          <v-subheader>(*) - {{ $t('chartByRegionTab.subheader') }}</v-subheader>
        </v-col>
      </v-row>
      <highcharts
        v-if="show"
        :chart-data="chartData"
      />
      <v-alert
        v-else
        border="bottom"
        colored-border
        type="warning"
        elevation="2"
      >{{ $t('chartByRegionTab.warning') }}</v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash.debounce';

import Highcharts from '@/components/Highcharts.vue';

export default {
  name: 'ChartByRegion',
  data: () => ({
    show: false,
  }),
  components: {
    Highcharts,
  },
  computed: {
    ...mapState(['reportByLocation', 'isReportByLocationLoading', 'search']),

    debounceRunSearchQuery() {
      return debounce(this.getItems, this.$store.getters.APITimeout);
    },
    labelSettings() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return [-90, 0, 'middle', 8];
        case 'sm': return [-90, 0, 'middle', 9];
        case 'md': return [-90, 0, 'middle', 10];
        default: return [0, -20, 'bottom', 10];
      }
    },
    chartData() {
      if (!this.reportByLocation.length) {
        return {};
      }
      const groupedByPortal = {};
      const vehicles = {};
      this.reportByLocation.forEach((item) => {
        if (!Object.prototype.hasOwnProperty.call(groupedByPortal, item.source)) {
          groupedByPortal[item.source] = {};
        }
        if (!Object.prototype.hasOwnProperty.call(vehicles, item.vehicle_class)) {
          vehicles[item.vehicle_class] = [];
        }
        vehicles[item.vehicle_class].push(item.avg_pay_now || item.avg_pay_later);
        groupedByPortal[item.source][item.vehicle_class] = item.avg_pay_now || item.avg_pay_later;
      });

      const categories = Object.keys(vehicles).sort();
      const series = Object.entries(groupedByPortal).map(([portal, value]) => {
        const data = categories.map(
          (vehicle) => (Object.prototype.hasOwnProperty.call(value, vehicle) ? value[vehicle] : 0),
        );
        return {
          name: portal,
          data,
        };
      });

      const [rotation, y, verticalAlign, fontSize] = this.labelSettings;
      return {
        chart: { type: 'line' },
        title: {
          text: this.$t('chartByRegionTab.legend', {
            region: this.search.locations[0],
            period: this.$t(this.search.rentalPeriods[0].text),
          }),
        },
        xAxis: {
          categories,
          title: { text: this.$t('chartByRegionTab.xAxis') },
          // eslint-disable-next-line no-unused-vars
          plotBands: Object.entries(vehicles).map(([_, array], index) => ({
            from: index,
            to: index + 1,
            color: 'none',
            zIndex: 99,
            label: {
              text: this.$t('chartByRegionTab.plotBandLabel',
                { price: array.sort()[Math.floor(array.length / 2)] }),
              verticalAlign,
              y,
              rotation,
              style: { fontSize, fontWeight: 'bold', whiteSpace: 'nowrap' },
              align: 'left',
              textAlign: 'center',
            },
          })),
          type: 'category',
          gridLineWidth: 2,
          gridLineDashStyle: 'longdash',
          startOnTick: true,
        },
        yAxis: { title: { text: this.$t('chartByRegionTab.yAxis') } },
        series,
        legend: { symbolWidth: 40 },
      };
    },
  },
  watch: {
    search: {
      deep: true,
      handler() {
        this.show = this.isSingleSelected();
      },
    },
  },
  mounted() {
    this.show = this.isSingleSelected();
    this.$root.$on('chart-by-region', () => {
      this.debounceRunSearchQuery();
    });
    if (!this.reportByLocation.length) {
      this.debounceRunSearchQuery();
    }
  },
  methods: {
    ...mapActions(['getReportByLocation']),

    getItems() {
      if (!this.show) return;
      this.getReportByLocation({ search: { ...this.search } });
    },
    isSingleSelected() {
      return this.search.locations.length === 1 && this.search.rentalPeriods.length === 1;
    },
  },
};
</script>
